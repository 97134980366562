import React from 'react';
import trivialData from '../data/trivial_data.json'
import igor from '../assets/images/Igor_500px.png';
import jurgen from '../assets/images/Jurgen_500px.png';
import piet from '../assets/images/Piet_500px.png';

export default function Initiative() {
    const pictures = [
        {name: "Jurgen", picture: jurgen},
        {name: "Igor", picture: igor},
        {name: "Piet", picture: piet}
    ]
    const founderFlex = trivialData.Foundation.teamMember.map((item, key) => {
        // const image = pictures.find((pic) => pic.name === item.firstname);
        return <div className="founder-item" key={key}>
            {/*<img className="founder-img" src={image.picture} alt={item.firstname}></img>*/}
            <h4>{item.firstname} {item.lastname}</h4>
        </div>
    })
    return (
        <div>
            <header className="page-header">
                <h1>Het bestuur</h1>
            </header>
            <article className="njf-article">
                <div className="founder-items">
                    {founderFlex}
                </div>
            </article>
        </div>
    )
}
